import { 
    imgInputTexts,  
    tableText, 
    hasError,
    errorMessage,
    alertProperties
} from '@/_helpers/funciones';

  //Data:

  //Metodos:
  function getBadge(status) {
    if(['ACTIVO',1,true].includes(status))
      return 'success'
    if(['INACTIVO',0,false].includes(status))
      return 'danger';
  }

  function getBadgeServiceStatus(status) {
    switch (status) {
      case 'ACTIVO':
        return 'success';
      case 'INACTIVO':
        return 'danger';
      case 'FINALIZADO':
        return 'warning';
      case 'CANCELADO':
        return 'warning';
    }
  }

  //Computeds
  function desactivado() {
    return this.$v.$invalid
  }

  function statusSelectColor(){
    if  (this.Status){
      return this.Status === 1;
    } else {
      return false;
    }
  }

  function archivoDescargable(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
    return false;
    /*
    OTRA MANERA
    let url = window.URL.createObjectURL(new Blob([data]))
    let link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', 'excel.xlsx')

    document.body.appendChild(link)
    link.click()
    */
  }
  
  export default {
    methods: {
      getBadge,
      hasError,
      errorMessage,
      statusSelectColor,
      getBadgeServiceStatus,
      alertProperties,
      archivoDescargable
    },
    computed: {
      desactivado,
      imgInputTexts, 
      tableText
    }
  }